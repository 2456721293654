<template>
  <div>
    <div class="excelImport">
      <br />
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-container class="bg-light" v-else>
        <b-row>
          <b-col md="12">
            <div class="closeBtn mb-2 text-right">
              <p @click="$router.push({ name: 'institute' })" class="btn m-0">
                <i class="fas fa-window-close fa-4x text-danger"></i>
              </p>
            </div>
            <h4 class="font-weight-bolder">
              Import Questions for:
              <span class="text-info">{{ survey.name }}</span>
            </h4>
            <br />
            <!-- <p v-if="surveyQuestions.length != 0">{{ surveyQuestions[0] }}</p> -->
            <div class="selectExcelFile">
              <div class="d-flex align-items-center">
                <b-form-file ref="selectedExcelFile" style="display: none" v-model="excelSheet"
                  placeholder="Select Excel File"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></b-form-file>
                <b-button variant="info" v-if="excelSheet == null" pill size="md" @click="selectExcelFile()"><i
                    class="fas fa-file-excel mr-2"></i>Select Excel
                  File</b-button>

                <h6 v-else class="text-dark font-weight-bolder">
                  {{ excelSheet.name }}
                  <span v-if="!questionSubmit.disabled" class="text-danger btn m-0 p-0" @click="removeExcelSheet()"><i
                      class="fas fa-trash ml-2"></i></span>
                </h6>
                <b-button v-if="surveyQuestions.length == 0" variant="outline-success" @click="checkExcelSheet"
                  class="text-info ml-3" :disabled="excelSheet == null || surveyQuestions.length != 0"><i
                    class="fas fa-upload fa-2x ml-2"></i></b-button>
              </div>

              <div class="sampleCsv" v-if="surveyQuestions.length == 0">
                <p class="text-info d-flex align-items-baseline">
                  <b class="text-dark mr-2">Note: </b> While preparing the Excel sheet, only include the serial number,
                  course outcome name, and course outcome description. Headers are not required.
                  <br />
                  Please refer to the sample image below:
                </p>
                <img src="../../../../../assets/images/surveyExcel.png" alt="" class="img-fluid" />
              </div>

              <div class="progressBar" v-if="loadData">
                <b-progress :value="value" :max="max" show-progress animated variant="success"></b-progress>
              </div>

              <div class="surveyQuestions">
                <b-form @submit.prevent="prepareSurveyQuestionsForAPI()" v-if="surveyQuestions.length != 0">
                  <div class="questions">
                    <div class="question border border-info rounded mt-2 p-3 bg-light"
                      v-for="(question, questionIndex) in surveyQuestions" :key="questionIndex">
                      <b-overlay :show="loadQuestion" spinner-variant="primary" spinner-type="grow" spinner-small
                        rounded="md">
                        <div class="questionNo d-flex justify-content-between">
                          <b-form-input style="width: 100px; text-align: center" placeholder="Q No."
                            v-model="question.question_no" :class="{
                              'form-group-error':
                                $v.surveyQuestions.$each[questionIndex]
                                  .question_no.$error,
                            }" :disabled="question.disabled"></b-form-input>
                          <span class="btn p-0 m-0 text-danger" @click="surveyQuestions.splice(questionIndex, 1)"><i
                              class="fas fa-minus-circle"></i></span>
                        </div>
                        <span class="input-error-message" v-if="
                          !$v.surveyQuestions.$each[questionIndex].question_no
                            .required &&
                          $v.surveyQuestions.$each[questionIndex]
                            .question_no.$dirty
                        ">Question Number is mandatory!</span>
                        <div class="question mt-2">
                          <b-form-input style="text-align: center" placeholder="Question" v-model="question.name"
                            :class="{
                              'form-group-error':
                                $v.surveyQuestions.$each[questionIndex].name
                                  .$error,
                            }" :disabled="questionSubmit.disabled"></b-form-input>
                        </div>
                        <span class="input-error-message" v-if="
                          !$v.surveyQuestions.$each[questionIndex].name
                            .required &&
                          $v.surveyQuestions.$each[questionIndex].name
                            .$dirty
                        ">Question is mandatory!</span>
                        <div class="choices mt-2">
                          <b-form-input class="text-center" placeholder="Enter options separated by commas"
                            v-model="question.choices" id="questionChoices" :disabled="questionSubmit.disabled">
                          </b-form-input>
                          <b-form-input placeholder="Enter scores separated by commas" v-model="question.scores"
                            class="text-center mt-2" id="questionScores" :disabled="questionSubmit.disabled">
                          </b-form-input>
                        </div>

                        <div class="mt-2 courseOutcome" v-if="survey.survey_type == 'course_exit'">
                          <!-- <b-form-select
                            :options="courseOutcomesOptions"
                            v-model="question.course_outcome_id"
                            :class="{
                              'form-group-error':
                                $v.surveyQuestions.$each[questionIndex].course_outcome_id
                                  .$error,
                            }"
                          ></b-form-select>
                          <span
                            class="input-error-message"
                            v-if="
                              !$v.surveyQuestions.$each[questionIndex].course_outcome_id
                                .required &&
                              $v.surveyQuestions.$each[questionIndex].course_outcome_id
                                .$dirty
                            "
                            >Please Select a course Outcome!</span
                          > -->
                          <!-- <b-form-select
                            :options="courseOutcomesOptions"
                            v-model="question.course_outcome_id"
                          ></b-form-select> -->
                          <multiselect v-model="question.course_outcomes" :options="courseOutcomesOptions"
                            :multiple="true" :close-on-select="false" :clear-on-select="false"
                            placeholder="Select Course outcomes" label="text" track-by="value" :preselect-first="false">
                          </multiselect>
                        </div>

                        <div class="mt-2 poOrPso" v-if="survey.survey_type == 'programme_exit'">
                          <!-- <b-form-select
                            :options="coursePoAndPosOptions"
                            v-model="question.poOrPso"
                            :class="{
                              'form-group-error':
                                $v.surveyQuestions.$each[questionIndex].poOrPso
                                  .$error,
                            }"
                          ></b-form-select>
                          <span
                            class="input-error-message"
                            v-if="
                              !$v.surveyQuestions.$each[questionIndex].poOrPso
                                .required &&
                                $v.surveyQuestions.$each[questionIndex].poOrPso
                                  .$dirty
                            "
                            >Please Select a PO/PSO!</span
                          > -->
                          <multiselect v-model="question.programme_outcomes" :options="poOptions" :multiple="true"
                            :close-on-select="false" :clear-on-select="false" placeholder="Select Programme outcomes"
                            label="text" track-by="value" :preselect-first="false" :disabled="
                              question.programme_specificoutcomes.length > 0
                            ">
                          </multiselect>
                          <multiselect v-model="question.programme_specificoutcomes" :options="psoOptions"
                            :multiple="true" :close-on-select="false" :clear-on-select="false"
                            placeholder="Select Programme specific outcomes" label="text" track-by="value"
                            :preselect-first="false" :disabled="question.programme_outcomes.length > 0">
                          </multiselect>
                        </div>

                        <div class="questionOptions d-flex justify-content-center mt-2">
                          <b-form-checkbox v-model.trim="question.allow_descriptive"
                            :disabled="questionSubmit.disabled">Allow Text Field</b-form-checkbox>
                          <b-form-checkbox v-model.trim="question.answer_required" :disabled="questionSubmit.disabled"
                            class="ml-3">Answer Mandatory</b-form-checkbox>
                        </div>
                      </b-overlay>
                    </div>
                  </div>

                  <div v-if="surveyQuestions.length != 0">
                    <b-tooltip target="questionChoices" title="Example for options" triggers="hover" placement="bottom">
                      <p>Option A, Option B, Option C, Option D</p>
                    </b-tooltip>
                    <b-tooltip target="questionScores" title="Example for scores" triggers="hover" placement="bottom">
                      <p>10, 5, 9, 7</p>
                    </b-tooltip>

                    <div class="submit mt-2 text-center">
                      <b-button type="submit" variant="success" size="md" @click="addQuestion = false"
                        :disabled="questionSubmit.disabled">{{ questionSubmit.submit }}</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { required } from "vuelidate/lib/validators";

export default {
  created() {
    this.getSurvey();
  },
  data() {
    return {
      fetchData: false,
      loadQuestion: false,
      survey: {},
      excelSheet: null,
      excelTable: [],
      surveyQuestionsDataFromExcel: [],
      surveyQuestions: [],
      loadData: false,
      value: 50,
      max: 100,
      questionSubmit: {
        submit: "Submit",
        disabled: false,
      },
      courseOutcomesOptions: [],
      // coursePoAndPosOptions: [],
      poOptions: [],
      psoOptions: [],
    };
  },
  validations: {
    surveyQuestions: {
      required,
      $each: {
        question_no: {
          required,
        },
        name: {
          required,
        },
        // course_outcome_id: {
        //   required: requiredIf(function() {
        //     if (this.survey.survey_type == "course_exit") {
        //       return true;
        //     }
        //   }),
        // },
        // poOrPso: {
        //   required: requiredIf(function() {
        //     if (this.survey.survey_type == "programme_exit") {
        //       return true;
        //     }
        //   }),
        // },
      },
    },
  },
  methods: {
    //********* Prepare Survey Questions For API **********/
    async prepareSurveyQuestionsForAPI() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.questionSubmit.submit = "Please wait...";
        this.questionSubmit.disabled = true;
        this.loadQuestion = true;

        for (let sQuestion in this.surveyQuestions) {
          let course_outcomes = [];
          await this.surveyQuestions[sQuestion].course_outcomes.forEach(
            (co) => {
              course_outcomes.push(co.value);
            }
          );
          let programme_outcomes = [];
          await this.surveyQuestions[sQuestion].programme_outcomes.forEach(
            (co) => {
              programme_outcomes.push(co.value);
            }
          );
          let programme_specificoutcomes = [];
          await this.surveyQuestions[
            sQuestion
          ].programme_specificoutcomes.forEach((co) => {
            programme_specificoutcomes.push(co.value);
          });

          let surveyQuestion = await {
            question_no: `${this.surveyQuestions[sQuestion].question_no}`,
            name: this.surveyQuestions[sQuestion].name,
            allow_descriptive: this.surveyQuestions[sQuestion]
              .allow_descriptive,
            answer_required: this.surveyQuestions[sQuestion].answer_required,
            survey_id: `${this.surveyQuestions[sQuestion].survey_id}`,
            survey_model: this.surveyQuestions[sQuestion].survey_model,
            course_outcomes: course_outcomes,
            programme_specificoutcomes: programme_specificoutcomes,
            programme_outcomes: programme_outcomes,
          };
          const questionCreated = await this.createSurveyQuestion(
            surveyQuestion
          );
          if (questionCreated) {
            this.$toast.success(
              `Question number ${this.surveyQuestions[sQuestion].question_no} created`,
              {
                position: "top-right",
                duration: 3000,
              }
            );
            const question = await this.surveyQuestions[sQuestion];
            if (
              this.surveyQuestions[sQuestion].choices != "" &&
              this.surveyQuestions[sQuestion].scores != ""
            ) {
              await this.createQuestionChoices(question);
            }

            if (sQuestion == this.surveyQuestions.length - 1) {
              this.$toast.success(`All questions created successfully`, {
                position: "top",
                duration: 3000,
              });
              this.clearResponse(true);
            }
          }
          if (!questionCreated) {
            this.$toast.error("Something Went Wrong, please try again!", {
              position: "top",
              duration: 3000,
            });
            this.clearResponse();
          }
        }
      }
    },
    //********* Create Survey Question API **********/
    async createSurveyQuestion(surveyQuestion) {
      const url = this.$store.getters.getAPIKey.surveyQuestions;
      let status = null;
      await this.$axios
        .post(url, surveyQuestion)
        .then((response) => {
          this.questionId = response.data.id;
          status = true;
          console.log(response);
        })
        .catch((error) => {
          status = false;
          console.log(error.response);
        });
      return status;
    },
    //********* Create Survey Question Choices **********/
    async createQuestionChoices(question) {
      const choices = question.choices.split(",");
      const scores = question.scores.split(",");
      if (choices.length != scores.length) {
        return await this.$toast.error(
          `Invalid option/scores provided for question number ${question.question_no}, Please check!`,
          {
            position: "top",
            duration: 4000,
          }
        );
      }
      const questionChoicesPostData = {
        survey_question_choices: [],
      };

      await choices.forEach((choice, choiceIndex) => {
        questionChoicesPostData.survey_question_choices.push({
          id: "",
          name: choice,
          score: scores[choiceIndex],
        });
      });
      const url =
        this.$store.getters.getAPIKey.surveyQuestions +
        `/${this.questionId}/surveychoices`;

      await this.$axios
        .post(url, questionChoicesPostData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    //********* Clear UI Responses */
    clearResponse(status) {
      this.questionSubmit.submit = "Submit";
      setTimeout(() => {
        if (status) {
          this.$router.push({ name: "institute" });
        }
        this.questionSubmit.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
    //Getting Excel Data Ready
    selectExcelFile() {
      this.$refs.selectedExcelFile.$refs.input.click();
    },
    removeExcelSheet() {
      this.excelTable = [];
      this.surveyQuestionsDataFromExcel = [];
      this.surveyQuestions = [];
      this.excelSheet = null;
    },
    //******** Getting Survey Details ********/
    async getSurvey() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          if (this.survey.survey_type == "course_exit") {
            this.getCourseOutcomes();
          }
          if (this.survey.survey_type == "programme_exit") {
            this.getPoAndPos();
          }
          console.log(response);
          this.fetchData = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async checkExcelSheet() {
      this.loadData = true;
      await this.excelSheet;

      await readXlsxFile(this.excelSheet).then((csv) => {
        this.surveyQuestionsDataFromExcel = csv;
        this.value = this.value + 10;
      });
      //   console.log(this.surveyQuestionsDataFromExcel);
      let checkValidation;
      await this.surveyQuestionsDataFromExcel.forEach((csv) => {
        if (csv.length > 4) {
          return (checkValidation = true);
        }
        if (csv.length <= 4) {
          return (checkValidation = false);
        }
      });

      console.log(checkValidation);
      if (checkValidation) {
        this.value = this.value + 10;
        this.$toast.error(
          "Invali format/Missing Data, Please check the excel sheet",
          {
            duration: 3000,
            position: "top",
          }
        );
        this.loadData = false;
        this.removeExcelSheet();
        setTimeout(() => {
          this.CsvError = false;
        }, 5000);
      } else {
        this.value = this.value + 20;
        await this.surveyQuestionsDataFromExcel.forEach((question) => {
          this.surveyQuestions.push({
            question_no: question[0],
            name: question[1],
            choices: question[2],
            scores: question[3],
            allow_descriptive: false,
            answer_required: true,
            survey_id: this.survey.id,
            survey_model: "StudFBSurvey",
            course_outcomes: [],
            programme_specificoutcomes: [],
            programme_outcomes: [],
            // poOrPso: null,
          });
        });
        this.loadData = false;
        this.value = 50;
      }
    },
    //********* Get Course Outcomes **********/
    getCourseOutcomes() {
      let outcomeURL = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = outcomeURL.replace("course_id", this.survey.course_id);
      this.$axios
        .get(url)
        .then((response) => {
          const outcomes = response.data;
          this.courseOutcomesOptions = [
            {
              value: null,
              text:
                outcomes.length == 0 ? "No Outcomes Found" : "Select Outcome",
            },
          ];
          outcomes.forEach((outcome) => {
            this.courseOutcomesOptions.push({
              value: outcome.id,
              text: outcome.code,
            });
          });

          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getPoAndPos() {
      await this.getProgramOutcomes();
      await this.getProgramSpecificOutcomes();
    },

    async getProgramOutcomes() {
      const url = this.$store.getters.getAPIKey.programOutcome;
      await this.$axios
        .get(url)
        .then((response) => {
          const programOutcomes = response.data;
          this.poOptions = [
            {
              value: null,
              text: "Select PO or PSO",
            },
          ];
          programOutcomes.forEach((po) => {
            this.poOptions.push({
              value: po.id,
              text: po.code,
            });
          });
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getProgramSpecificOutcomes() {
      const url = this.$store.getters.getAPIKey.programSpecificOutcome;
      await this.$axios
        .get(url)
        .then((response) => {
          const programSpecificOutcomes = response.data;
          this.psoOptions = [
            {
              value: null,
              text: "Select PO or PSO",
            },
          ];
          programSpecificOutcomes.forEach((pso) => {
            this.psoOptions.push({
              value: pso.id,
              text: pso.code,
            });
          });
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style >
.excelImport .container {
  min-height: 100vh;
  padding: 10px;
}
</style>
